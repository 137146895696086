h1{
    font-size: 24px;
    font-weight: 600;
    margin-left: 12px;
}
.card-container {
    display: flex;
    border-radius: 12px;
    border: 1px solid #ddd;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    width: 100%;
    height: 348px;
    margin: 0px 0px 30px 0px;
}

.card-container .description{
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.image-container {
    flex: 5;
    max-width: 40%;
    flex-shrink: 0;
    position: relative;
    background-color: lightgrey;
    display: flex;
    align-items: center;
    justify-content: center;
}

.image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}

.placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.content {
    display: flex;
    flex-direction: column;
    max-width: 60%;
    padding: 16px;
    height: 100%;
}

.body {
    flex: 8;
    max-height: 80%; 
    overflow: hidden;
}

.footer {
    /* display: flex;
    flex-direction: flex-end; */
    position: absolute;
    right: 16px;
    bottom: 20px;
    margin-top: 1rem;
    flex: 1;
    max-height: 10%;
}

.title {
    font-weight: 600;
    font-size: 21px;
    margin-bottom: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
b{
    font-weight: 600;
}

.work-details {
    display: flex;
    gap: 16px;
    margin-bottom: 8px;
}

.detail {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 4px;
}

.job-info, .loan-info, .accommodation-info {
    margin-bottom: 8px;
    margin-top: 8px;
}

.description {
    margin-bottom: 16px;
}

.actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.social-icons {
    display: flex;
    gap: 8px;
}

.buttons {
    display: flex;
    gap: 8px;
    align-items: center;
}

.heart-icon, .map-icon {
    width: 36px;
    cursor: pointer;
}

@media screen and (max-width: 1400px) {
    .list{
     padding-left: 35px;
     padding-right: 35px;
    }
 }
@media screen and (max-width: 980px) {
    .card-container {
        flex-direction: column;
        height: 689px;
        max-height: unset;
    }

    .image-container, .content {
        max-width: 100%;
        width: 100%;
        height: 50%;

    }
    .list{
        padding-left: 24px;
        padding-right: 24px;  
    }
}
@media screen and (max-width: 600px) {
.work-details{
    display: inline;
    font-size: 14px;
}
 }
@media screen and (max-width: 400px) {
    .list{
     padding-left: 16px;
     padding-right: 16px;
    }
    h1{
        font-size: 16px;
    }
 }

